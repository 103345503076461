<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item :key="contact.uid" transition="slide-y-transition" :disabled="$store.state.layout.searchActive"
               @click.stop="viewContactProfile"
  >
    <v-list-item-avatar size="50">
      <user-avatar-img :user="contact" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="contact.full_name" />
      <v-list-item-subtitle>
        <span class="secondary--text text--darken-1 font-weight-medium">{{ getJob(contact.job) }}</span>
        <template v-if="contact.main_address">
          à <address-displayer city-only inline :address="getAddress(contact.main_address)" />
        </template>
      </v-list-item-subtitle>
      <v-list-item-subtitle class="fs-80">
        <pro-specialties-displayer text :max="3" :specialties="contact.specialties" />
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="align-self-center flex-row">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onAction }">
          <v-btn icon :disabled="!contact.phone" v-on="onAction" @click.stop="call(contact.phone)">
            <font-awesome-icon fixed-width size="lg" :icon="['fal', 'phone']" :class="!contact.phone ? 'grey--text' : 'primary--text'" />
          </v-btn>
        </template>
        <span>Appeler le professionnel</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onAction }">
          <v-btn icon :disabled="!contact.email" v-on="onAction" @click.stop="sendmail(contact.email)">
            <font-awesome-icon fixed-width size="lg" :icon="['fal', 'at']" :class="!contact.email ? 'grey--text' : 'primary--text'" />
          </v-btn>
        </template>
        <span>Envoyer un e-mail</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import AddressDisplayer from '@/modules/core/components/AddressDisplayer'
  import {Person} from '@/models'
  import contactMethods from '@/mixins/contactMethods'
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'
  import ProSpecialtiesDisplayer from '@/modules/core/layout/ProSpecialtiesDisplayer'
  import conditionalData from '@/mixins/conditionalData'

  // TODO Déplacer et renommer ce composant pour qu'il puisse représenter un user plutot qu'un contact
  // TODO Ajouter les abilities pour masquer les actions non autorisés
  export default {
    name: 'ContactListItem',
    components: {ProSpecialtiesDisplayer, UserAvatarImg, AddressDisplayer},
    mixins: [contactMethods, conditionalData],
    props: {
      uid: String,
      disableMenu: Boolean
    },
    data() {
      return {}
    },
    computed: {
      contact() {
        return Person.get(this.uid)
      }
    },
    methods: {
      async remove(uid, fullName) {
        let confirm = await this.$confirm('Retirer ' + fullName + ' de vos contacts ?')

        if (!confirm) return

        await this.$store.dispatch('contacts/removeContact', uid)
      },
      addInfoSnackBar() {
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'info',
          title: 'En cours de traitement',
          subtitle: 'Le retrait de ce contact est en cours de réalisation.'
        })
      },

      addErrorSnackBar() {
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'error',
          title: 'Une erreur est survenue',
          subtitle: 'Le professionnel n\'a pas pu être retiré des contacts.'
        })
      },
      viewContactProfile() {
        this.$router.push({name: 'professional-profile', params: {uid: this.contact.uid}})
      }
    }
  }
</script>
