<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <contact-pending-requests-list v-if="showRequests" />
    <v-card class="elevation-x">
      <v-sheet :color="$vuetify.theme.dark ? '' :'grey lighten-3'" class="pa-2">
        <v-row>
          <v-col cols="12" md="9">
            <v-text-field dense filled rounded placeholder="Recherche par nom" hide-details />
          </v-col>
          <v-col cols="12" md="3" class="d-none d-md-block">
            <v-select dense filled rounded placeholder="ordre d'affichage" :items="sortingTypes" hide-details />
          </v-col>
        </v-row>
      </v-sheet>
      <v-divider />
      <empty-list v-if="total === 0" title="Aucun résultat"
                  subtitle="Vous n'avez aucun contact dans votre liste" actions="Ajouter un contact" @action-empty="addContact"
      />
      <v-list v-else class="pa-0 " three-line>
        <q-infinite-scroll ref="is"
                           :disable="total !== null && items.length >= total"
                           @load="nextPage"
        >
          <template v-for="(item, index) in items">
            <contact-list-item :key="item" :uid="item" />
            <v-divider v-if="index + 1 < items.length" :key="'divider-'+index" class="my-0" />
          </template>

          <template v-slot:loading>
            <v-progress-linear color="primary" indeterminate height="4" />
          </template>
        </q-infinite-scroll>
      </v-list>
    </v-card>
  </div>
</template>

<script>
  // Ce composant est réutilisable
  import EmptyList from '@/modules/core/layout/EmptyList'
  import {QInfiniteScroll} from 'quasar'
  import ContactListItem from '@/modules/network/components/contacts/ContactListItem'
  import {mapActions, mapState} from 'vuex'
  import ContactPendingRequestsList from '@/modules/network/components/contacts/ContactPendingRequestsList'

  export default {
    name: 'ContactsList',
    components: {ContactListItem, QInfiniteScroll, EmptyList, ContactPendingRequestsList},
    props: {
      filterBySpace: Boolean
    },
    data() {
      return {
        loading: true,
        sortingTypes: ['Date d\'ajout', 'Nom de famille'],
        showRequests: true
      }
    },
    computed: {
      ...mapState('contacts', ['items', 'page', 'total'])
    },
    watch: {
      filterBySpace() {
        this.$refs.is.setIndex(this.page)
        this.$refs.is.resume()
      }
    },
    methods: {
      ...mapActions('contacts', ['fetchPage']),
      reset() {
        this.$nextTick(() => {
          this.$refs.is.resume()
        })
      },
      async nextPage(index, done) {
        try {
          this.loading = true
          done(await this.fetchPage(index))
          this.loading = false
        } catch (e) {
          this.$handleApiError(e)
          done(true)
        }
      },
      addContact () {
        this.$root.$emit('modal:open', {
          modal: ()=> import('@/modules/network/components/contacts/InviteContact')
        })
      }
    }
  }
</script>
