<template>
  <v-card v-if="!loading && pendingRequests.length" class="mb-9" shaped outline elevation="0">
    <v-toolbar dense flat>
      <v-badge bordered bottom color="secondary" :content="pendingRequests.length" :value="pendingRequests.length > 0"
               offset-x="12"
               offset-y="15"
      >
        <v-avatar size="35" color="primary">
          <font-awesome-icon size="lg" class="white--text" :icon="['fad', 'user-plus']" />
        </v-avatar>
      </v-badge>
      <v-toolbar-title class="ml-4 mt-1 font-weight-light">Invitation(s) recue(s)</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-divider />
    <v-list>
      <template v-for="(user, index) in pendingRequests">
        <v-list-item :key="index">
          <v-list-item-avatar size="50">
            <user-avatar-img :user="user" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="user.full_name" />
            <v-list-item-subtitle class="primary--text" v-text="user.job.name" />
            <v-list-item-subtitle class="fs-80">
              <pro-specialties-displayer text :max="3" :specialties="user.specialties" />
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="align-self-center flex-row">
            <v-btn icon @click.stop="handleRequest(user.uid, true)">
              <font-awesome-icon fixed-width size="lg" :icon="['fal', 'check']" class="secondary--text" />
            </v-btn>
            <v-btn icon @click.stop="handleRequest(user.uid, false)">
              <font-awesome-icon fixed-width size="lg" :icon="['fal', 'times']" class="red--text text--darken-3" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index + 1 < pendingRequests.length" :key="'divider-'+index" class="my-0" />
      </template>
    </v-list>
  </v-card>
</template>

<script>
  import * as Contacts from '@/modules/network/api'
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'
  import ProSpecialtiesDisplayer from '@/modules/core/layout/ProSpecialtiesDisplayer'

  export default {
    name: 'ContactPendingRequestsList',
    components: {UserAvatarImg, ProSpecialtiesDisplayer},
    data() {
      return {
        pendingRequests: null,
        loading: true
      }
    },
    async created() {
      Contacts.getPendingRequests()
        .then(response => {
          this.pendingRequests = response.data.list
          this.loading = false
        })
        .catch(() => {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Une erreur est survenue veuillez réessayer.'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    methods: {
      removeEntry(removableUid) {
        this.pendingRequests = this.pendingRequests.filter(request => request.uid !== removableUid)
      },
      async handleRequest(uid, value) {
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'info',
          title: 'En cours de traitement',
          subtitle: 'Veuillez patienter l\'opération est en cours de traitement.'
        })
        Contacts.handleRequest(uid, value)
          .then(() => {
            return this.removeEntry(uid)
          })
          .catch(() => {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue',
              subtitle: 'Une erreur est survenue veuillez réessayer.'
            })
          })
          .finally(() => {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
              type: 'success',
              title: 'Opération effectuée',
              subtitle: 'Opération effectuée avec succès.'
            })
          })
      }
    }
  }
</script>
